var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('section',{staticClass:"mb-10"},[_c('h1',[_vm._v("Замовлення")]),_c('small',{staticClass:"d-block grey--text mb-6"},[_vm._v("ID: "+_vm._s(_vm.orderid))]),(_vm.order.is_status_changed)?_c('v-alert',{attrs:{"color":_vm.paymentResult,"outlined":"","text":""}},[_vm._v(" Статус: "+_vm._s(_vm.order.status === 'completed' ? 'Успішна оплата' : 'Невдала оплата')+" ")]):_vm._e(),_c('v-row',{staticClass:"mb-10"},_vm._l((_vm.order.order_products),function(orderProduct){return _c('v-col',{key:orderProduct.id,attrs:{"cols":"12"}},[_c('OrderProductCard',{attrs:{"order-product":orderProduct}})],1)}),1),_c('div',{staticClass:"d-flex justify-space-between align-end"},[_c('div',{staticClass:"title size-h5"},[_vm._v(" Загалом ")]),_c('div',{staticClass:"text-h3 font-weight-black font-italic success--text text-no-wrap"},[_vm._v(" "+_vm._s(_vm.order.total_amount)+"₴ ")])])],1),_c('section',{staticClass:"mb-10"},[(_vm.checkout_data && !_vm.order.is_status_changed)?_c('form',{attrs:{"method":"POST","action":_vm.checkout_data.url,"accept-charset":"utf-8"}},[_c('input',{attrs:{"type":"hidden","name":"data"},domProps:{"value":_vm.checkout_data.data}}),_c('input',{attrs:{"type":"hidden","name":"signature"},domProps:{"value":_vm.checkout_data.signature}}),_c('v-btn',{attrs:{"type":"submit","color":"green accent-4","loading":_vm.awaiting,"x-large":"","block":"","dark":""}},[_vm._v(" Оплатити ")]),_c('small',{staticClass:"text-center"},[_vm._v(" Вас буде перенаправлено на сайт LiqPay, де ви зможете оплатити замовлення ")])],1):_vm._e()]),_c('sweet-alert-vuetify',{attrs:{"alertDefault":"confirm","loading":!_vm.order.is_status_changed,"config":{
			title: _vm.paymentResultTitle,
			icon: _vm.paymentResult,
			buttonCancel: {
				visible: false,
			},
			buttonOk: {
				style: {
					color: _vm.paymentResult,
				}
			},
			loading: {
				text: 'Очікуємо підтвердження оплати...',
			},
		}},on:{"clickButtonOk":_vm.onDialogClose},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }