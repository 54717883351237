<template>
	<v-card
		elevation="24">

		<div class="d-flex">
			<v-list-item-avatar
				class="ma-3"
				size="125"
				rounded>
				<v-img
					:src="product.image">
				</v-img>
			</v-list-item-avatar>

			<div class="d-flex justify-space-between flex-grow-1">
				<div class="d-flex flex-column flex-sm-row justify-space-between flex-grow-1">
					<div>
						<v-card-title class="pb-1">
							{{ product.name }}
						</v-card-title>

						<v-card-text>
							<small class="grey--text">{{ author.fullname }}</small>
						</v-card-text>
					</div>

					<v-card-title class="text-h4 font-weight-black text-no-wrap">
						{{ orderProduct.total_amount }}₴
					</v-card-title>
				</div>
			</div>
		</div>
	</v-card>
</template>

<script>
import User from '../../models/User'

export default {
	props: {
		orderProduct: {
			type: Object,
			default: () => ({}),
		},
	},
	computed: {
		product() {
			return this.orderProduct.product || {}
		},
		course() {
			return this.product.courses[0] || {}
		},
		author() {
			return new User(this.course.author || {})
		},
	},
}
</script>
