<template>
	<v-container>
		<section class="mb-10">
			<h1>Замовлення</h1>
			<small class="d-block grey--text mb-6">ID: {{ orderid }}</small>

			<v-alert
				v-if="order.is_status_changed"
				:color="paymentResult"
				outlined
				text>
				Статус: {{ order.status === 'completed' ? 'Успішна оплата' : 'Невдала оплата' }}
			</v-alert>

			<v-row class="mb-10">
				<v-col
					v-for="orderProduct in order.order_products"
					:key="orderProduct.id"
					cols="12">
					<OrderProductCard
						:order-product="orderProduct">
					</OrderProductCard>
				</v-col>
			</v-row>

			<div class="d-flex justify-space-between align-end">
				<div class="title size-h5">
					Загалом
				</div>

				<div class="text-h3 font-weight-black font-italic success--text text-no-wrap">
					{{ order.total_amount }}₴
				</div>
			</div>
		</section>

		<section class="mb-10">
			<form
				v-if="checkout_data && !order.is_status_changed"
				method="POST"
				:action="checkout_data.url"
				accept-charset="utf-8">
				<input
					type="hidden"
					name="data"
					:value="checkout_data.data"/>
				<input
					type="hidden"
					name="signature"
					:value="checkout_data.signature"/>
				<v-btn
					type="submit"
					color="green accent-4"
					:loading="awaiting"
					x-large
					block
					dark>
					Оплатити
				</v-btn>
				<small class="text-center">
					Вас буде перенаправлено на сайт LiqPay, де ви зможете оплатити замовлення
				</small>
			</form>
		</section>

		<sweet-alert-vuetify
			v-model="showDialog"
			alertDefault="confirm"
			:loading="!order.is_status_changed"
			:config="{
				title: paymentResultTitle,
				icon: paymentResult,
				buttonCancel: {
					visible: false,
				},
				buttonOk: {
					style: {
						color: paymentResult,
					}
				},
				loading: {
					text: 'Очікуємо підтвердження оплати...',
				},
			}"
			@clickButtonOk="onDialogClose">
		</sweet-alert-vuetify>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import Order from '../models/Order'

import OrderProductCard from '../components/Cart/OrderProductCard'

export default {
	components: {
		OrderProductCard,
	},
	props: {
		orderid: {
			type: String,
			required: true,
		},
		awaiting: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		showDialog: false,
	}),
	computed: {
		...mapGetters('cart', [
			'items',
			'totalAmount',
		]),
		order() {
			return Order.find(this.orderid) || {}
		},
		checkout_data() {
			return this.order?.checkout_data
		},
		paymentResult() {
			return this.order?.status === 'completed'
				? 'success'
				: 'error'
		},
		paymentResultTitle() {
			return this.order?.status === 'completed'
				? 'Ура! Платіж отримано. Дякуємо 😘'
				: 'О ні! Щось пішло не так'
		},
	},
	methods: {
		...mapActions('notification', [
			'notify',
		]),

		async loadOrder(orderid) {
			try {
				this.loading = true
				await Order.fetchGet(orderid)
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити замовлення',
					color: 'error',
				}, { root: true })
				throw error
			} finally {
				this.loading = false
			}
		},

		onDialogClose() {
			if (this.order?.status === 'completed') {
				this.$router.push({ name: 'home' })
			}
		},
	},
	mounted() {
		this.loadOrder(this.orderid)

		if (this.awaiting) {
			this.showDialog = true
			Order.poll(
				this.orderid,
				5000,
				() => this.order.is_status_changed,
			)
		}
	},
}
</script>
